import React from 'react'
import Modal, { Styles } from 'react-modal'
import styled from '@emotion/styled'
import CommonBtn from '../atoms/commonBtn'
import BreakPoints from '../../styles/breakPoints'
import useRouteParam from '../../hooks/useRouteParam'

// react-modalの初期設定
// root nodeのSelectorを設定
Modal.setAppElement('#___gatsby')
const modalStyle: Styles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 100,
  },
  content: {
    position: 'relative',
    overflowY: 'auto',
    width: '430px',
    minHeight: '100px',
    backgroundColor: '#FFFFFF',
    borderRadius: '1rem',
    padding: '1.5rem',
  },
}

const Div = styled.div`
  .title-area {
    h3 {
      margin: 0 0 10px;
      height: 40px;
      display: flex;
      width: 100%;
      span {
        display: block;
        width: 100%;
        text-align: center;
        &: before {
          width: 20%;
        }
      }
    }
  }

  .content-area {
    flex: 1 1 auto;
    padding: 0;
    overflow-y: auto;
    .message {
      padding: 10px 0;
      text-align: center;
      white-space: pre-wrap;
    }
  }
  .footer-area {
    padding: 20px 0 0 0;
    flex: 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    button {
      margin: 0 20px;
      width: 122px;
    }
  }
  ${BreakPoints.small} {
    .footer-area {
      button {
        width: 110px;
        padding: 0px;
      }
    }
  }
`
export type ConfirmPopupType = 'close' | 'good' | 'bad'
type IProps = {
  type: ConfirmPopupType
  onClose: () => void
}
/**
 * 確認ポップアップ
 * @constructor
 */
const ConfirmPopup = ({ type, onClose }: IProps) => {
  const routeParam = useRouteParam('/support')
  return (
    <Modal
      isOpen={type !== 'close'}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      style={modalStyle}
    >
      <Div>
        {/* タイトルが必要な場合はコメントアウトを解除 */}
        {/* <div className="title-area"> */}
        {/*  <CommonH3 label="確認ポップアップ" /> */}
        {/* </div> */}
        <div className="content-area">
          <p className="message">
            貴重なご意見ありがとうございます。
            <br />
            {type === 'bad' && (
              <>
                問題が解決しない場合は
                <a href={routeParam} target="_blank" rel="noreferrer">
                  お問い合わせフォーム
                </a>
                よりお問い合わせください。
              </>
            )}
          </p>
        </div>
        <div className="footer-area">
          <CommonBtn onClick={onClose} size="medium">
            閉じる
          </CommonBtn>
        </div>
      </Div>
    </Modal>
  )
}

export default ConfirmPopup
